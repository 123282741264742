<template>
  <div>
    <v-card  v-if="event">
      <div>
        <v-toolbar v-if="eventUtil.newLayout" elevation="0" color="grey lighten-5" class="split-toolbar">
          <v-toolbar-title class="ml-3 py-2 subtitle">
            {{ event.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="actions">
            <v-btn v-if="event.published" :to="{name:'event', params: {id: event.id}}" class="me-2">
              <v-icon small class="me-2">fadl fa fa-eye</v-icon>
              View
            </v-btn>
            <v-btn v-if="eventUtil.hasFullAccess()" :to="configRouteData" class="me-2">
              <v-icon small class="me-2">fadl fa fa-pencil</v-icon>
              Edit
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon title="More options" >fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
              <v-list-item v-if="event && event.published && event.deeplink_url" @click="showQrCode = true">
                <v-list-item-icon><v-icon>fal fa-qrcode</v-icon></v-list-item-icon>
                <v-list-item-title>Open in App</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="eventUtil.hasFullAccess()" @click="archiveEvent">
                <v-list-item-icon><v-icon>fal fa-archive</v-icon></v-list-item-icon>
                <v-list-item-title>Archive/End Challenge</v-list-item-title>
              </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-toolbar>
        <v-container class="info-container grey lighten-5 py-0">
          <v-row>
            <v-col cols="12" sm="6">
              <!-- <div class="label">ID</div>
              <div class="value"><pre>{{event.id}}</pre></div> -->
              
              <div class="label">Start date (included)</div>
              <div class="value">
                {{event.from | localDate("L")}}
                <v-chip  v-if="$helpers.isActiveEvent(event)" outlined color="accent darken-0" class="white--text">Active now!</v-chip>
                <v-chip  v-else-if="$helpers.isFutureEvent(event)" outlined color="accent darken-0" class="white--text">Opens in {{$helpers.getStartDurationForEvent(event) | duration(true)}}</v-chip>
              </div>

              <div class="label">Finish date (included)</div>
              <div class="value">{{event.till | localDate("L")}}</div>
            </v-col>
            
          </v-row>
        </v-container>
        <v-card-actions v-if="event.org" class="grey lighten-5">
          <v-btn text :to="{name:'organizationChallengesFeed', params: {id: event.org.id, eventId: event.id}}" color="accent">
            <v-icon small class="mr-2">fadl fa fa-bullhorn</v-icon> Feed
          </v-btn>
        </v-card-actions>

        <v-alert v-if="$helpers.isPastEvent(event)" tile type="info" class="mb-0">
          <p>This challenge has ended. No new activities may count.</p>
        </v-alert>
        <v-alert v-else-if="!event.published" tile type="info" class="mb-0">
          <p>This challenge is not yet published. A challenge must be published before it can be used or viewed.</p>
          <v-btn v-if="eventUtil.hasFullAccess()" :to="configRouteData">Finalize Setup</v-btn>
        </v-alert>

        <v-alert v-if="event.validation && event.validation.meta.status == 'ERROR'" tile type="error" class="mb-0">
          <p>There are one or more validation issues. Please resolve these before going live:</p>
          <ul class="font-weight-bold"><li v-for="(item,idx) in event.validation.data" :key="idx">{{item.msg}}</li></ul>
        </v-alert>
        <v-alert v-if="event.validation && event.validation.meta.status == 'INFO'" tile type="info" class="mb-0">
          <p>There are one or more settings that might need your attention. Please have a look before going live:</p>
          <ul class="font-weight-bold"><li v-for="(item,idx) in event.validation.data" :key="idx">{{item.msg}}</li></ul>
        </v-alert>

        <div v-if="event.published && org" class="grey lighten-3">
          <v-card-title class="subtitle">
            Invite Information
          </v-card-title>
          <v-card-text>
            <p>Get your co-workers to join this challenge. Only users part of the <strong>{{org.name}}</strong> organization may join this challenge.</p>
            <p v-if="joinInfo && joinInfo.join_code">Join code <v-chip outlined>{{joinInfo.join_code}}</v-chip></p>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="accent" small @click="$helpers.copyToClipboard(joinUrl, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy Link</v-btn>
            <v-dialog v-if="tenant.id == 'cofi'" max-width="400px">
              <template v-slot:activator="{ on }">
                <v-btn text small color="accent" v-on="on">
                  <v-icon small class="mr-2">fadl fa fa-qrcode</v-icon> View QR code
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Join Event QR</v-card-title>
                <v-card-text>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</v-card-text>
                <v-card-text>Share this code with the other participants so they can join as well.</v-card-text>
                <QrCode :data="joinUrl" />
              </v-card>
            </v-dialog>
            <v-btn text small color="accent" @click="emailInviteToMe">
              <v-icon small class="mr-2">fadl fa fa-envelope</v-icon> Email me
            </v-btn>
          </v-card-actions>
        </div>
        <v-data-table
          :headers="raceHeaders"
          :items="event.races"
          class="grey lighten-4 pb-4"
          sort-by="dist"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="transparent" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Leaderboard(s)</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.activity_types="{ item }">
            <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
            <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
              <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
              <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
            </span>
          </template>
          <template v-slot:item.scoring="{ item }">
            <v-icon :title="item.scoring">{{$helpers.getScoringIcon(item.scoring)}}</v-icon>
          </template>
          <template v-slot:item.dist="{ item }">
            {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event) }}
            <div v-if="item.min_dist" class="text-muted">
              Activities: {{ $helpers.getDistanceForDisplay($options, item, item.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, item, item.max_dist, event.unit) }}
            </div>
            <div v-if="item.from" class="text-muted">
              {{item.from | localDate("L", /* inOriginalTimeZone */ true)}} - {{item.till | localDate("L", /* inOriginalTimeZone */ true)}}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name }} 
            <div v-if="item.team" class="font-weight-bold">({{$helpers.displayText('team_types', item.team, 'UNKNOWN') }})</div>
            <div v-if="item.type === 'HYBRID'" class="font-weight-bold">(hybrid)</div>
            <div v-if="item.type === 'EXTERNAL'" class="font-weight-bold">(in-person)</div>
          </template>
          <template v-slot:no-data>
            <i>No leaderboards created, add at least one leaderboard to this event.</i>
          </template>
        </v-data-table>

        <div v-if="event.published && eventUtil.hasFullAccess()">
          <v-card-title class="subtitle pt-4">
            <span v-if="$store.getters.isLoading && !participants" class="mr-1">  
              <v-icon small>fa fa-circle-notch fa-spin</v-icon>
            </span>
            <span v-else>{{ !participants ? 0 : participants.length}} joined users</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table v-if="participants"
            :headers="participantHeaders"
            :items="participants"
            :items-per-page="15"
            :search="search"
            >
            <template v-slot:item.join_date="{ item }">
              {{ item.join_date | localDate('L LT') }}
            </template>
          </v-data-table>
        </div>

        <v-dialog v-if="event && event.deeplink_url" v-model="showQrCode" max-width="400px">
          <v-card>
            <v-card-title>View in app</v-card-title>
            <v-card-text>
              <v-text-field readonly v-model="event.deeplink_url"/>
              <v-btn outlined block class="shrink" @click="$helpers.copyToClipboard(event.deeplink_url, $toast);showQrCode=false;">Copy URL to clipboard</v-btn>
            </v-card-text>
            <v-card-text>Scan this QR code with your phone to quickly open this event in our app. This will download the iOS or Android depending on the phone.</v-card-text>
            <QrCode :data="event.deeplink_url" />
          </v-card>
        </v-dialog>      
      </div>
    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import QrCode from '@/components/generic/QrCode.vue'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ViewUGC",
  components: {
    QrCode,
  },
  props: {
    configRouteData: Object,
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      error: null,
      event: null,
      search: null,
      participants: null,
      showQrCode: false,
      joinInfo: null,
      raceHeaders: [
        { text: 'Activities', align: 'start', sortable: false, value: 'activity_types',},
        { text: 'Scoring', align: 'start', sortable: false, value: 'scoring',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Goal', value: 'dist', sortable: true },
      ],
      participantHeaders: [
        { text: 'Profile Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Joined at', align: 'start', sortable: true, filterable: false, value: 'join_date',},
        { text: 'Profile Id', align: 'start', sortable: true, value: 'profile_id_last_digits',},
      ],
    };
  },
  async mounted() {
   
  },
  methods: {
    async loadEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      this.licenseInfo = (await eventManagerService.getLicenseStatus(id)).data;
      this.joinInfo = { join_url: this.event.join_url };
      if (this.event.verification_method === 'ALLOWLIST') {
        this.joinInfo = (await eventManagerService.getJoinInfo(id)).data;
      }
      this.participants = (await eventManagerService.getParticipants(id)).data.data;
    },

    async emailInviteToMe() {
      const response = await eventManagerService.sendInviteEventEmailToCode(this.event.id, this.joinInfo.join_code);
      this.$helpers.toastResponse(this, response.data, 'The invite email is on it\'s way! Please check your inbox in a few minutes from now.');
    },

    async archiveEvent() {
      if (confirm('Are you sure you want to archive this event? This will end the challenge and hide it from your challenge list.')) {
        const response = await eventManagerService.archiveEvent(this.event.id);
        this.$helpers.toastResponse(this, response.data, 'This event has been archived.');
        if (response.data.status == "OK") {
          this.loadEvent(this.event.id);
        }
      }
    },

  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    joinUrl() {
      if (this.event == null) {
        return null;
      }
      return this.joinInfo ? this.joinInfo.join_url : this.event.join_url;
    },
    unitTypeDiplay() {
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .size--xs .split-toolbar  {
    height: auto !important;
    .v-toolbar__content {
      height: auto !important;
      flex-direction: column;
      align-items: start;
      div.actions {
        align-self: flex-end;
      }
    }
  }
  .full-dialog { 
      width:100vw; 
      height: 100vh;
      /*.toastui-editor-defaultUI { position: absolute; top:0; bottom: 0; right: 0; left: 0;}*/
    }
</style>

